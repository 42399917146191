import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const categories = [
    { id: 1, name: 'Optimisation Aéro-Structure', image: '/images/aerostructure.png' },
    { id: 2, name: 'Optimisation Aérodynamique', image: '/images/aerodynamique.png' },
    { id: 3, name: 'Optimisation de la Structure', image: '/images/structure.png' },
    { id: 4, name: 'Simulation physique', image: '/images/physique.png' },
  ];

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-200 flex flex-col">
      <header className={`fixed w-full z-10 transition-all duration-300 ${scrollPosition > 20 ? 'bg-white shadow-md' : 'bg-transparent'}`}>
        <div className="container mx-auto flex justify-between items-center p-4">
          <div className="flex items-center">
            <img
              src="/images/logo.png"
              alt="MyMDO Logo"
              className="w-36"
            />
          </div>
          <nav className="hidden md:flex space-x-6">
            {['Accueil', 'À propos', 'Contact'].map((item) => (
              <Link key={item} to={item === 'Accueil' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`} className="text-[#283593] hover:text-[#4CAF50] font-medium transition-colors">
                {item}
              </Link>
            ))}
          </nav>
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full flex items-center justify-center text-white font-bold">
              U
            </div>
            <span className="hidden md:inline text-[#283593]">Utilisateur</span>
            <button className="bg-[#283593] text-white hover:bg-[#1a237e] transition-colors px-4 py-2 rounded-full shadow-md">
              Déconnexion
            </button>
          </div>
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden text-[#283593]">
            <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
            </svg>
          </button>
        </div>
        {isMenuOpen && (
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="md:hidden bg-white shadow-md"
          >
            {['Accueil', 'À propos', 'Contact'].map((item) => (
              <Link key={item} to={item === 'Accueil' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`} className="block py-2 px-4 text-[#283593] hover:bg-gray-100">
                {item}
              </Link>
            ))}
          </motion.div>
        )}
      </header>

      <main className="flex-grow container mx-auto p-4 pt-24">
        <motion.h2 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-4xl font-bold mb-12 text-[#283593] text-center"
        >
          Solutions Aérospatiales Innovantes
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {categories.map((category, index) => (
            <motion.div
              key={category.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Link to={`/wizard/${category.id}`} className="block">
                <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105">
                  <img 
                    src={category.image} 
                    alt={category.name}
                    className="w-full h-48 object-cover" 
                  />
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-[#283593] mb-2">{category.name}</h3>
                    <p className="text-gray-600">Solutions innovantes pour l'industrie aérospatiale</p>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
      </main>

      <footer className="bg-[#283593] text-white p-8 mt-12">
        <div className="container mx-auto text-center">
          <img
            src="/images/logo.png"
            alt="MyMDO Logo"
            className="w-24 mx-auto mb-4 invert"
          />
          <p className="mb-4">&copy; 2024 MyMDO. Tous droits réservés.</p>
          {/* <div className="flex justify-center space-x-4">
            {['Facebook', 'Twitter', 'LinkedIn'].map((social) => (
              <a key={social} href="#" className="hover:text-[#4CAF50] transition-colors">
                {social}
              </a>
            ))}
          </div> */}
        </div>
      </footer>
    </div>
  );
}