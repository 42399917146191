import React, { useState } from 'react';

export default function LoginRegister() {
  const [activeTab, setActiveTab] = useState("login");

  return (
    <div className="min-h-screen bg-[#E0E0E0] flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md">
        <div className="flex flex-col items-center mb-8">
          <img
            src="images/logo.png"
            alt="MyMDO Logo"
            width={200}
            height={80}
            className="mb-6"
          />
          <h1 className="text-2xl font-bold text-center text-[#283593] mb-2">
            Bienvenue dans le monde de l'aérospatiale
          </h1>
          <p className="text-center text-gray-600">
            avec MyMDO - Votre plateforme de design aérospatiale
          </p>
        </div>
        
        <div className="w-full">
          <div className="grid w-full grid-cols-2">
            <button 
              onClick={() => setActiveTab("login")} 
              className={`py-2 ${activeTab === "login" ? "bg-white" : "bg-gray-200"}`}
            >
              Connexion
            </button>
            <button 
              onClick={() => setActiveTab("register")} 
              className={`py-2 ${activeTab === "register" ? "bg-white" : "bg-gray-200"}`}
            >
              Inscription
            </button>
          </div>
          {activeTab === "login" && (
            <div className="mt-4">
              <form className="space-y-4">
                <input type="email" placeholder="Email" className="w-full p-2 border rounded" />
                <input type="password" placeholder="Mot de passe" className="w-full p-2 border rounded" />
                <button type="submit" className="w-full bg-[#283593] hover:bg-[#1a237e] text-white p-2 rounded">
                  Se connecter
                </button>
              </form>
            </div>
          )}
          {activeTab === "register" && (
            <div className="mt-4">
              <form className="space-y-4">
                <input type="text" placeholder="Nom" className="w-full p-2 border rounded" />
                <input type="email" placeholder="Email" className="w-full p-2 border rounded" />
                <input type="password" placeholder="Mot de passe" className="w-full p-2 border rounded" />
                <input type="password" placeholder="Confirmer le mot de passe" className="w-full p-2 border rounded" />
                <button type="submit" className="w-full bg-[#283593] hover:bg-[#1a237e] text-white p-2 rounded">
                  S'inscrire
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}