import React, { useState } from 'react';

export default function Wizard() {
  const [step, setStep] = useState(1);

  const nextStep = () => setStep((prev) => Math.min(prev + 1, 4));
  const prevStep = () => setStep((prev) => Math.max(prev - 1, 1));

  return (
    <div className="min-h-screen bg-[#E0E0E0] flex flex-col">
      <header className="bg-white shadow-md p-4">
        <div className="container mx-auto">
          <img
            src="/images/logo.png"
            alt="MyMDO Logo"
            className="w-36"
          />
        </div>
      </header>

      <div className="flex-grow container mx-auto flex items-center justify-center p-4">
        <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg p-8">
          <div className="mb-6 bg-gray-200 h-2 rounded-full">
            <div 
              className="bg-[#283593] h-2 rounded-full transition-all duration-500 ease-in-out" 
              style={{ width: `${step * 25}%` }}
            ></div>
          </div>
          <h2 className="text-2xl font-bold mb-6 text-[#283593]">Configuration - Étape {step} sur 4</h2>
          
          {step === 1 && (
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Configuration Initiale</h3>
              {/* Contenu de l'étape 1 */}
            </div>
          )}

          {step === 2 && (
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Paramètres Techniques</h3>
              {/* Contenu de l'étape 2 */}
            </div>
          )}

          {step === 3 && (
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Spécifications Aérospatiales</h3>
              {/* Contenu de l'étape 3 */}
            </div>
          )}

          {step === 4 && (
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Validation Finale</h3>
              {/* Contenu de l'étape 4 */}
            </div>
          )}

          <div className="flex justify-between mt-8">
            <button
              onClick={prevStep}
              disabled={step === 1}
              className="bg-[#E8D000] hover:bg-[#FDD835] text-white
               font-medium px-4 py-2 rounded disabled:opacity-50"
            >
              Précédent
            </button>
            <button
              onClick={step === 4 ? () => console.log('Formulaire soumis') : nextStep}
              className="bg-[#4CAF50] hover:bg-[#45a049] text-white px-4 py-2 rounded"
            >
              {step === 4 ? 'Soumettre' : 'Suivant'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}